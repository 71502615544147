import React, { Suspense, Fragment, lazy } from "react"
import { Switch, Redirect, Route } from "react-router-dom"
import LoadingScreen from "components/LoadingScreen/LoadingScreen"
import ShopLayout from "layouts/shop"
import AdminLayout from "layouts/AdminLayout"
import BrandMindsLayout from "layouts/BrandMindsLayout"
import { adminRoutes } from "routes/admin.routes"
import { shopDevRoutes } from "routes/shop.dev"
import AuthGuard from "components/AuthGuard"
import store from "store/index"
import { loadDynamoItemsBySK } from "store/modules/dataView.search"
import HelmetMetaData, { defaultPageTitle } from "components/HelmetMetaData"
import { myLazy } from "utils/myLazy"
import { campaignConfig, GoToCampaign, isCampaignPartiallyActive } from "views/shoppingCart/useCampaignInfo"

store.dispatch(
  loadDynamoItemsBySK({ SK: "MAINTENANCE-SK", dictionary: "MAINTENANCE" })
)

const defaultmeta = [
  { property: "og:title", content: "Routes Title meta" },
  { property: "product:availability", content: "instock" }
]

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes?.map?.((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component
        const guardProps = route.guard
          ? {path: route?.path}
          : undefined
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard {...guardProps}>
                <HelmetMetaData
                  {...route}
                  // pageTitle='ASDASDS'

                />
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  </Suspense>
)

let routes = [
  {
    exact: true,
    path: "/maintenance",
    component: myLazy(() => import("views/MaintenancePage/MaintenancePage"))
  },
  {
    path: "/admin",
    layout: AdminLayout,
    routes: [...adminRoutes || []],
    guard: AuthGuard
  },
  // {
  //   path: "xyz",
  //   routes: [
  //     {
  //       component: () => <Redirect to="/maintenance" />
  //     }
  //   ]
  // },
  {
    path: "/shop",
    layout: ShopLayout,
    routes: [
      // {
      //   path: "xyz",
      //   routes: [
      //     {
      //       component: () => <Redirect to="/maintenance" />
      //     }
      //   ]
      // },
      ...shopDevRoutes,
      // {
      //   path: "/shop/dev",
      //   routes: shopDevRoutes,
      // },
      {
        exact: true,
        // guard: () => <div data-test-id='xFAc'>no access</div>,
        path: "/shop/productlist",
        component: myLazy(() => import("views/productsList")) // DONE // Localization/Balazs/Done
      },
      {
        exact: true,
        path: "/shop/details/:customUrl",
        component: myLazy(() => import("views/productDetails")),// DONE // Localization/Balazs/Done
      },
      {
        exact: true,
        path: "/shop/details",
        component: myLazy(() => import("views/productDetails")), // DONE // Localization/Balazs/Done
      },
      {
        // exact: true,
        path: "/shop/cart",
        component: myLazy(() => import("views/shoppingCart")), // DONE  // Localization/Balazs/Done 90%
        guard: AuthGuard
      },
      {
        exact: true,
        path: "/shop/dashboard",
        component: myLazy(() => import("views/dashboard")),// NEXT || PROGRESS ==========================  // Localization/Balazs/Done 80%
        guard: AuthGuard
      },
      {
        exact: true,
        path: "/shop/invoices",
        component: myLazy(() => import("views/invoices/index_2")),// Localization/Balazs/Done
        // component: myLazy(() => import("views/invoices")),
        guard: AuthGuard
      },
      // {
      //   exact: true,
      //   path: "/shop/invoices/facturi",
      //   component: myLazy(() => import("views/invoices/facturi"))
      // },
      // {
      //   exact: true,
      //   path: "/shop/invoices/comenzi",
      //   component: myLazy(() => import("views/invoices/comenzi"))
      // },
      {
        exact: true,
        path: "/shop/orders",
        component: myLazy(() => import("views/orders")), // Localization/Balazs/Done
        guard: AuthGuard
      },
      {
        // exact: true,
        path: "/shop/orders/details",
        component: myLazy(() => import("views/shoppingCart/orderDetails.js")), // DONE
        guard: AuthGuard
      },
      {
        exact: true,
        path: "/shop/offers",
        component: myLazy(() => import("views/offerts")),
        guard: AuthGuard
      },
      {
        // exact: true,
        path: "/shop/offers/details",
        component: myLazy(() => import("views/offerts/offerDetails.jsx")), // DONE
        guard: AuthGuard
      },
      {
        // exact: true,
        path: "/shop/invoice/details",
        component: myLazy(() => import("views/invoices/invoiceDetails")), // DONE
        guard: AuthGuard
      },
      {
        exact: true,
        path: "/shop/lists",
        // component: myLazy(() => import("views/lists")),
        component: myLazy(() => import("views/lists/listsView")), // Localization/Balazs/Done
        guard: AuthGuard
      },
      {
        exact: true,
        path: "/shop/news",
        pageTitle: `${defaultPageTitle} - Blog`,
        component: myLazy(() => import("views/guest/news/GuestCardsList")) // Localization/Balazs/Done
      },
      {
        exact: true,
        path: "/shop/careers",
        pageTitle: `${defaultPageTitle} - Cariere`,
        component: myLazy(() => import("views/guest/news/GuestCardsList")), // Localization/Balazs/Done
        guard: AuthGuard
      },
      {
        exact: true,
        path: "/shop/predefinedlists",
        pageTitle: `${defaultPageTitle} - Liste create de experții Uniprest Instal`,
        component: myLazy(() => import("views/guest/news/GuestCardsList")) // Localization/Balazs/Done
      },
      {
        exact: true,
        path: "/shop/tutorials",
        component: myLazy(() => import("views/guest/news/GuestCardsList")), // Localization/Balazs/Done
        guard: AuthGuard
      },
      {
        exact: true,
        path: "/shop/about",
        pageTitle: `${defaultPageTitle} - Despre noi`,
        component: myLazy(() => import("views/guest/about")) // Localization/Balazs/Done/there was no text
      },
      {
        exact: false,
        path: "/shop/article/:customUrl",
        component: myLazy(() =>
          import("views/guest/news/GuestArticleDetails.jsx")
        )
      },
      {
        exact: false,
        path: "/shop/unilist/:customUrl",
        component: myLazy(() =>
          import("views/guest/news/GuestArticleDetails.jsx")
        )
      },
      {
        exact: false,
        path: "/shop/tutorial/:customUrl",
        component: myLazy(() =>
          import("views/guest/news/GuestArticleDetails.jsx")
        ),
        guard: AuthGuard
      },
      // {
      //   exact: true,
      //   path: "/shop/article/preview/:customUrl",
      //   component: myLazy(() => import("views/guest/news/GuestArticleDetails.jsx")),
      // },
      {
        exact: false,
        path: "/shop/job/:customUrl",
        component: myLazy(() =>
          import("views/guest/news/GuestArticleDetails.jsx")
        ),
        guard: AuthGuard
      },
      // {
      //   exact: true,
      //   path: "/shop/job/preview/:customUrl",
      //   component: myLazy(() => import("views/guest/news/GuestArticleDetails.jsx")),
      // },
      {
        exact: true,//?????????????????????????????????????
        path: "/shop/my-list",
        component: myLazy(() =>
          import("views/guest/news/GuestArticleDetails.jsx")
        ),
      },
      // {
      //   exact: true,
      //   path: "/shop/article2",
      //   component: myLazy(() => import("views/guest/article2")),
      // },
      {
        exact: true,
        path: "/shop/lists/details",
        component: myLazy(() =>
          import("views/registeredUser/listDetails/index.jsx")
        ),
        guard: AuthGuard
      },
      {
        exact: true,
        path: "/shop/services",
        pageTitle: `${defaultPageTitle} - Servicii`,
        component: myLazy(() => import("views/guest/services")) // Localization/Istvan/Done
      },
      {
        exact: true,
        path: "/shop/partners",
        pageTitle: `${defaultPageTitle} - Parteneri`,
        component: myLazy(() => import("views/guest/partners")) // Localization/Balazs/Done
      },

      {
        exact: true,
        path: "/shop/map",
        pageTitle: `${defaultPageTitle} - Unde ne găsești`,
        component: myLazy(() => import("views/guest/map2")) // changed with map2 ,  the old is map // Localization/Istvan/Done
      },
      {
        exact: true,
        path: "/shop/support",
        pageTitle: `${defaultPageTitle} - Contact`,
        component: myLazy(() => import("views/guest/support")) // Localization/Balazs/Done
      },
      {
        exact: true,
        path: "/shop/terms",
        component: myLazy(() => import("views/guest/terms"))
        // component: myLazy(() => lazyRetry(() => import("views/guest/terms")))
      },
      {
        exact: true,
        path: "/shop/signup",
        pageTitle: `${defaultPageTitle} - Solicită un cont`,
        component: myLazy(() => import("views/signUp/signUp.view")), // Localization/Balazs/Done
        guard: AuthGuard
      },
      {
        exact: true,
        path: "/shop/signin",
        pageTitle: `${defaultPageTitle} - Login`,
        component: myLazy(() => import("views/signIn/signIn.view")), // Localization/Balazs/Done
        guard: AuthGuard
      },

      // {
      //   exact: true,
      //   path: "/shop/profile",
      //   component: myLazy(() => import("views/profile/profile"))
      // },
      {
        exact: true,
        path: "/shop/client/mycompany",
        component: myLazy(() =>
          import("views/client/companiileMele/companiileMele")
        ),
        guard: AuthGuard
      },
      {
        exact: true,
        path: "/shop/client/users",
        component: myLazy(() => import("views/client/utilizatori")),
        guard: AuthGuard
      },
      // {
      //   exact: true,//?????????????????????????
      //   path: "/shop/createcompany",
      //   component: myLazy(() => import("views/createCompany/createCompany"))
      // },
      // {
      //   exact: true,//??????????????????????
      //   // path: "/shop/searchResults",
      //   // component: myLazy(() => import("./views/searchResults"))
      //   path: "/shop/settings",
      //   component: myLazy(() => import("views/setari/Setari"))
      // },
      // {
      //   exact: true,//??????????????????????
      //   // path: "/shop/searchResults",
      //   // component: myLazy(() => import("./views/searchResults"))
      //   path: "/shop/company/users",
      //   component: myLazy(() => import("views/setari/Setari"))
      // },
      {
        exact: true,
        path: "/shop/client/profile",
        component: myLazy(() => import("views/client/myProfile/myProfile")),
        guard: AuthGuard
      },
      // {
      //   exact: true,
      //   path: "/shop/adduser",
      //   component: myLazy(() => import("views/addUser/AddUser"))
      // },
      // {
      //   exact: true,//??????????????
      //   path: "/shop/addproduct",
      //   component: myLazy(() => import("views/addProduct/AddProduct"))
      // },
      // {
      //   exact: true,//??????????????
      //   path: "/shop/oferta",
      //   component: myLazy(() => import("views/oferta/ofertaPret"))
      // },
      {
        exact: true,
        path: "/shop/alerts",
        component: myLazy(() => import("views/alert/Alert")),
        guard: AuthGuard
      },
      // {
      //   exact: true,
      //   path: "/shop/mylist",
      //   component: myLazy(() => import("views/mylist")),
      // },
      // {
      //   exact: true,
      //   path: "/shop/job-old",
      //   component: myLazy(() => import("views/guest/job")),
      // },
      // {
      //   exact: true,//??????????????????
      //   path: "/shop/searchResults",
      //   component: myLazy(() => import("views/searchResults"))
      // },
      {
        exact: true,
        path: "/shop/excelimport",
        component: myLazy(() => import("views/ImportFromExcel/index.jsx")), // DONE // Localization/Balazs/Done
        //component: myLazy(() => import("views/ExcelImports/listsView.jsx")) // DONE  // Localization/Balazs/Done
        guard: AuthGuard
      },
      // {
      //   exact: true,
      //   path: "/shop/importfromexcel/details",
      //   component: myLazy(() => import("views/ImportFromExcel/index.jsx")) // DONE // Localization/Balazs/Done
      // },
      {
        component: myLazy(() => import("views/404Page/404Page"))
      }
    ]
  },
  {
    exact: true,
    path: "/dev/sorin",
    component: myLazy(() => import("views/dev/sorin"))
  },

  {
    exact: true,
    path: "/dev/sorin3",
    component: myLazy(() => import("views/dev/sorin/index3"))
  },
  {
    exact: true,
    path: "/dev/sorin5",
    component: myLazy(() => import("views/dev/sorin/index5"))
  },
  {
    exact: true,
    path: "/dev/sorin6",
    component: myLazy(() => import("views/dev/sorin/index6"))
  },

  {
    exact: true,
    path: "/dev/gyula/toastr",
    component: myLazy(() => import("views/dev/gyula/Toastr/index"))
  },

  // {
  //   exact: true,
  //   path: "/",
  //   component: myLazy(() => import("views/guest/landing")),
  // },
  {
    exact: true,
    path: "/",
    pageTitle: "Uniprest",
    component: myLazy(() => import("views/dev/sorin/NewHomePage"))
  },

  {
    exact: true,
    path: "/dev/erno",
    component: myLazy(() => import("views/dev/erno"))
  },
  {
    exact: true,
    path: "/dev/erno2",
    component: myLazy(() => import("views/dev/erno/index2"))
  },
  {
    exact: true,
    layout: ShopLayout,
    path: "/login",
    component: myLazy(() => import("views/signIn/signIn.view")),
    guard: AuthGuard
  },
  {
    exact: true,
    layout: ShopLayout,
    path: "/password-reset",
    component: myLazy(() => import("views/passwordReset/passwordReset"))
  },
  {
    exact: true,
    layout: ShopLayout,
    path: "/invitationaccept",
    component: myLazy(() => import("views/passwordReset/passwordReset"))
  },
  {
    exact: true,
    path: "/dev/norbert",
    component: myLazy(() => import("views/dev/norbert"))
  },
  {
    exact: true,
    path: "/dev/tamas",
    component: myLazy(() => import("views/dev/tamas"))
  },
  {
    exact: true,
    path: "/safariNotSupported",
    component: myLazy(() => import("views/MaintenancePage/MaintenancePage"))
  },
  {
    exact: true,
    // guard: () => <div data-test-id='xFAc'>no access</div>,
    path: "/media/black-friday.pdf",
    component: GoToCampaign
  },
  {
    exact: true,
    // guard: () => <div data-test-id='xFAc'>no access</div>,
    path: "/media/black-friday.html",
    component: GoToCampaign 
  },
  {
    exact: true,
    layout: ShopLayout,
    path: "/404",
    component: myLazy(() => import("views/404Page/404Page"))
  },
  // {
  //   exact: true,
  //   path: "/shop/admin/users/list",
  //   component: myLazy(() => import("./views/userTable/usersTable")),
  // },
  {
    exact: true,
    layout: ShopLayout,
    path: "/:customUrl/pd/:productId",
    pageMeta: defaultmeta,
    component: myLazy(() => import("views/productDetails")) // DONE // Localization/Balazs/Done
  },
  {
    exact: true,
    layout: ShopLayout,
    // guard: () => <div data-test-id='ImfP'>no access</div>,
    path: "/:customUrl/ct",
    component: myLazy(() => import("views/productsList")) // DONE // Localization/Balazs/Done
  },
  
  {
    path: "*",
    layout: ShopLayout,
    // layout: DashboardLayout,
    routes: [
      // {
      //   exact: true,
      //   path: '/',
      //   component: () => <Redirect to="/app/manage/users" />
      // },
      {
        component: myLazy(() => import("views/404Page/404Page"))
      }
    ]
  }
]


const campaignLandingPageRoute = {
  exact: true,
  path: "/un_viitor_digital_impreuna",
  pageTitle: "Un viitor digital împreună",
  component: myLazy(() => import("views/campaignLanding/CampaignLandingPage"))
}



export default (
  isCampaignPartiallyActive() && campaignConfig.doesAffectRouting
)
  ? [campaignLandingPageRoute, ...routes || []]
  : routes
