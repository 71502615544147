import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import SvgIcon from "components/SvgIcon/SvgIcon.jsx"
import { setFilterSelection } from "store/modules/dataView"
import { getCategorySvgName } from "./MegaCategoryFilter"

import useQueryStringFilters from "utils/useQueryStringFilters"
import MyLink from "components/MyLink"
//$version = "v-0.1.2"

// const mockProps = {
//   id: "FLT-CATEG001"
// }

function CategoryFilter(props) {
  //console.log("FILTER PROPS ", props);
  const {
    id,
    value: externalValue,
    setValue: externalSetValue,
    noTopMargin,
    withQs,
    setCategory,
    shouldNotRenderAsLinks
  } = props
  const { data, selected, setValue, categoryClick } = useCategoryFilterLogic({
    
    
    id,
    setCategory,
    externalValue,
    externalSetValue,
    withQs,
  })



  
  const lastSelected = selected?.[selected?.length - 1]

  const categoryIndentMultiplier =
    selected?.length > 3 ? selected?.length : selected?.length - 1
  const categoryIndentValue =
    selected?.length > 3 ? 7 : selected?.length > 2 ? 14 : 0
  const categoryIndent = categoryIndentMultiplier * categoryIndentValue + "px"

  const itemIndentMultilplier =
    selected?.length > 3
      ? selected?.length - 2
      : selected?.length > 2
      ? selected?.length - (lastSelected?.childList?.length > 0 ? 1 : 2)
      : selected?.length > 1
      ? selected?.length - 1
      : selected?.length
  const itemIndentValue = selected?.length > 1 ? 28 : 0
  const itemIndent = itemIndentMultilplier * itemIndentValue + "px"

  const categProps = (item) => ({
    key: item?.id,
    onClick: () => categoryClick(item),
    style: { "--indentValue": categoryIndent }
  })
  const onCategBackClick = (e, item) => {
    e?.stopPropagation?.()
    categoryClick(item, true)
  }

  const itemProps = (item) => ({
    key: item?.id,
    className: `categ-item ${item?.treeCode === "#27610" ? "special" : ""} ${lastSelected?.id === item?.id ? "selected" : ""} ${
      selected?.length > 1 ? "spaced" : ""
    }`,
    to: item?.href,
    onClick: (e) => {
      e?.stopPropagation?.()
      setValue(id, item)
    },
    style: {
      "--indentValue":
        selected?.length > 1 && selected?.length < 5 ? itemIndent : "0px"
    }
  })

  return (
    <div 
      data-test-id='Sz5B'
      className={`category-filter-wrapper ${
        noTopMargin ? "no-top-margin" : ""
      }`}
    >
      {selected?.map?.(
        (item, index) =>
          (item?.childList?.length > 0 || item?.treeCode === "#27610")  && (
            <div 
              data-test-id='pqYU'
              {...categProps(item)}
              className={
                selected?.length > 0 && index === 0
                  ? "categ-category selected"
                  : "categ-category"
              }
            >
              <div data-test-id='iIQS' className="icon-title">
                {index === 0 ? (
                  <SvgIcon name={getCategorySvgName(item?.treeCode)} />
                ) : (
                  <span data-test-id='94uP' onClick={(e) => onCategBackClick(e, item)}>
                    <SvgIcon
                      name="listback"
                      h={5}
                      w={5}
                      style={{ stroke: "black" }}
                    />
                  </span>
                )}

                <div data-test-id='rz86' className={`categ-label ${item?.treeCode === "#27610" ? "special" : ""}`}>{`${item?.label}`}</div>

                
                {index === 0 ? (
                  <span data-test-id='QyzB' onClick={(e) => onCategBackClick(e, item)}>
                    <SvgIcon
                      name="closeround"
                      h={5}
                      w={5}
                      style={{ stroke: "black" }}
                    />
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          )
      )}
      {data?.map?.((item) => (
          <MyLink {...itemProps(item)} shouldNotRenderAsLink={shouldNotRenderAsLinks}>
            {selected?.length === 0 ? (
              <span data-test-id='X2hw' className="icon">
                <SvgIcon name={getCategorySvgName(item?.id)} />
              </span>
            ) : selected?.length === 1 && item?.childList?.length > 0 ? (
              <SvgIcon
                name="selectdown"
                h={5}
                w={5}
                style={{ stroke: "black" }}
              />
            ) : selected?.length === 1 && item?.childList?.length === 0 ? (
              <span data-test-id='5Dtn' className="empty-icon"></span>
            ) : (
              ""
            )}
            {item?.label}
            {selected?.length > 1 ? (
              <span data-test-id='aS9i' className="icon">
                {item?.childList?.length > 0 && <SvgIcon name="add" h={5} w={5} />}
              </span>
            ) : (
              ""
            )}
          </MyLink>
      ))}
    </div>
  )
}

export default CategoryFilter

// const sameParentCategory = (id1, id2) => {
//   const getParentCode = id => id?.split?.("#")?.slice?.(0,-1)?.join?.("#")
//   return getParentCode(id1) === getParentCode(id2)
// }
const useCategoryFilterLogic = (props) => {
  //console.log("FILTER LOGIC PROPS ", props);
  const { externalValue, externalSetValue, setCategory, withQs } = props
  const cateogries = useSelector((state) => state.backData?.productCategory)
  const dispatch = useDispatch()
  const { query, setFilter, getUrlWithNewCateg } = useQueryStringFilters()
  const selectedInStoreStateRedux = useSelector(
    (state) => state?.dataView?.selectedFilters?.["FLT-CATEG001"]
  )
  const selectedInStoreState = withQs
    ? query?.filters?.["FLT-CATEG001"]
    : selectedInStoreStateRedux
  const isDeletedState = selectedInStoreState?.[0]

  const populateInitial = () => {
    const splittedTreeCode = setCategory
      ? externalValue?.treeCode?.split?.("#")?.slice?.(1)
      : isDeletedState?.split?.("#")?.slice?.(1)

    const arrayToReturn = []
    //debugger
    splittedTreeCode?.forEach?.((cat, i) => {
      if (i === 0)
        cateogries?.forEach?.(
          (i) =>
            i.treeCode === "#" + cat &&
            arrayToReturn?.push?.({ ...i, id: i.treeCode, label: i?.name })
        )
      if ((i > 0) & (i < splittedTreeCode?.length)) {
        const joinedTreeCode =
          "#" + splittedTreeCode?.slice?.(0, i + 1)?.join?.("#")

        arrayToReturn?.[arrayToReturn?.length - 1]?.childList?.forEach?.((i) => {
          i.treeCode === joinedTreeCode &&
            arrayToReturn?.push?.({ ...i, id: i.treeCode, label: i?.name })
        })
      }
    })
    return arrayToReturn
    
  }


  const externalSetAction = (id, treeCode) => {
    withQs
    
      ? setFilter(id, treeCode)
      : dispatch(setFilterSelection(id, treeCode))
  }

  const selected = populateInitial()
  //debugger
  //console.log("SELECTED ", selected)
  // const lastSelected = selected?.[selected?.length-1]
  const setValue = (id, value) => {
    //console.log("SET VALUE CLICK");
    // if (!value.childList?.length > 0) {
    if (!withQs && externalSetValue) {

      externalSetValue([value?.treeCode])
    }
    if (!withQs && props.setCategory) {
      props.setCategory(value)
    }
    else {
      externalSetAction(props.id, value?.treeCode ? [value?.treeCode] : null)
    }
    // }
    // if (value?.id !== lastSelected?.id )
    //   if (sameParentCategory(value?.treeCode, lastSelected?.treeCode )) setSelected(p => [...p?.slice?.(0, -1), value])
    //   else setSelected(p => [...p, value])
  }

  const data = normalizeApiCategList(
    selected?.[selected?.length - 1]?.childList?.length > 0
      ? selected?.[selected?.length - 1]?.childList
      : selected?.[selected?.length - 2]?.childList?.length > 0
      ? selected?.[selected?.length - 2]?.childList
      : selected?.[0]?.treeCode === "#27610"
      ? null 
      : cateogries
  )


  
  const categoryClick = (item, back) => {
    let index = 0
    selected?.forEach?.((selItem, i) => {
      if (selItem.id === item.id) index = i
    })
    //setSelected(selected?.slice?.(0, back ? selected?.[selected?.length-2] === item ? index -2 : selected?.[selected?.length-3] === item ? index-3 : index -1 : index))
    setValue(props.id, selected?.[back ? index - 1 : index])
  }

  const dataWithHrefs = data?.map?.(dataItem => ({
    ...dataItem,
    href: getUrlWithNewCateg(dataItem?.id)
  }))


  return { data: dataWithHrefs, selected, setValue, categoryClick }
}

const normalizeApiCategList = (input) =>
  input?.map?.((item) => ({
    ...item,
    initialId: item?.id,
    id: item?.treeCode,
    label: item?.name
  }))
const backSvg = (
  <svg 
    data-test-id='gUjx'
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 8L10 12L14 16"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
