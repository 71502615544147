import React, { useEffect, useRef, useState } from "react"
import MagicButton from "components/MagicButton/MagicButton"
import MagicButton2 from "components/MagicButton/MagicButtonV2"
import Close from "assets/general/Close.svg"
import { getVolumeDiscountQuantities } from "store/justActions/miscApiInteractions"

import ProductRating from "components/ProductRating/ProductRating"
import MB from "components/MB/MB"
import { useDispatch, useSelector } from "react-redux"
import { addToBasket, modifyBasket, refreshBasket } from "store/modules/shop"
import { useHistory } from "react-router"
import imageChain3 from "components/_partials/ImageChain3"
import SvgIcon from "components/SvgIcon/SvgIcon"
import InfoIcon from "components/InfoIcon"
import FastPickerCard from "components/ProductCard/FastPickerCard"
import SpecialPricesCard from "components/ProductCard/SpecialPricesCard"
import useShowModalOutsideClick from "utils/useShowModalOutsideClick"
import { round } from "utils/serviceFunctions"
import { getProductDetailHref, goToProductDetails } from "utils/history"
import { numberFormatter } from "utils/stringUtils"
import { useUserInfo } from "utils/useUserInfo"
import {
  setPageModal,
  setPageModalData,
  resetCrtList
} from "store/modules/page"
import {
  deleteProductFromList,
  updateListQuantityAction
} from "store/justActions/lists"
import FormatComponent from "components/FormatComponent/FormatComponent"
import { saveStockRequest, savePriceRequest } from "store/modules/guest.actions"
import { setExcelQuantity, setLastAddedToCart } from "store/modules/dataView"
import { loadItem, loadAccessories } from "store/justActions/loadApiIems"
//import BigModal from 'components/_modals/BigModal'
//import CartProductCard from "views/shoppingCart/cartProductCard"
//import { loadItem } from "store/justActions/loadApiIems"
import Left from "assets/dashboard/Icons/Left.svg"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
import useIsLogged from "utils/useIsLogged"
import { convertCategoryTreeToList } from "utils/stringUtils"
import { Link } from "react-router-dom"
import MyLink from "components/MyLink"
import { toastr } from "react-redux-toastr"
import { printError } from "utils/printError"
import { parseFloatOrZero } from "utils/numberUtils"
import useWindowSize from "utils/useWindowsSize"
import { hideMobileKeyboard } from "utils/hideKeyboardOnMobile"
import { attachSizeQueryParamsSmall, attachSizeQueryParamsXS } from "utils/attachSizeQueryParams"
import { defaultCurrencyCode } from "utils/currencyUtils"
import useQuantityValidation from "store/hooks/useQuantityValidation"
import { getComputedPriceInfo } from "utils/productUtils"


const getCTAtext = (clientType) => {
  switch (clientType) {
    case "GUEST":
      return <Lang value="productDetails.seeDetails" />
    case "SMALL":
      return <Lang value="productlist.AddCart" />
    case "RESELLER":
      return <Lang value="productlist.order" />
    case "LARGE":
      return <Lang value="productlist.Offert" />
    default:
      return <Lang value="productDetails.seeDetails" />
  }
}

const samplePicUrl =
  "https://uniprest.compano.com/Data/Environments/000001/Attachment/Bijlage/Overzicht%20Product%20classifications/light%2087gr.jpg"

function ProductCard(props) {
  let {
    //new stuff
    list, //if the product card is renderd in a list, we get the list item here.
    noPrice, //used in list to hide price
    noImage, //because we use this in the prod details also
    className,
    discountVolumeId, //configured if it has volume discount
    ratingAverage,
    ratingCount,
    long,
    badgeColor,
    centralStockQuantity,
    localStockQuantity,
    topLeftPrice,
    topRightPrice,
    bottomLeftPrice,
    rebateMessage,
    fastOptions,
    mainPrice,
    secondaryPrice,
    fullPrice,
    stockProps,
    listPrice,
    secondaryListPrice,
    item,
    currency,
    currencyCode,
    youSaveValue,
    images,
    minQuantity,
    maxDiscount,
    priceGross,
    priceNet,
    unitOfMeasure,
    stockMessageInfo,
    stockInfo,
    noStock,
    nullStock,
    /////
    renderedBy, // figure out where do we render this one
    addState,
    id,
    closeModal, //used if it's called from the long card as a modal
    product,
    badgeTitle,
    brandName,
    price,
    priceRange,
    priceListNet,
    youSave,
    title,
    stock,
    stockMessage,
    rating,
    reviews,
    deals,
    quantity,
    quantityUnit,
    totalPrice,
    noListButton,
    clientType: inputClientType, //GUEST | SMALL | RESELLER | LARGE |
    primaryImage,
    setScrollPosition,
    customTags,
    customUrl,
    code,
    mainImage,
    requestedPriceQuantity,
    priceDiscountType,
    isProductDetailPage,
    smallPackageQuantity,
    smallPackageType,
    nrOfProductsInCart,
    isBasketPage
  } = props

  const finalCurrency = currencyCode || currency || defaultCurrencyCode
  
  const computed = getComputedPriceInfo(props, true)

  if (minQuantity === undefined) {
    minQuantity = props?.sellQuantityMinim  }
  const productDetailHref = getProductDetailHref({ id })

  const translate = props.translate
  const shopSide = window.location.pathname === "/shop/productlist"
  const lastAddedToCartId = useSelector(
    (state) => state?.dataView.lastAddedToCart
  )
  const userPermissions = useSelector(
    (state) => state.auth?.userData?.role?.permissions
  )
  const loadingIndicators = useSelector((state) => state?.page?.loadingIndicators)
  const crtFormItem = useSelector((state) => state.page.crtFormItem)
  const itemImage = primaryImage ?? "/images/new-placeholder.svg"
  const { ref, showModal, setShowModal } = useShowModalOutsideClick()
  const { companyType } = useUserInfo()
  const [cardState, setCardState] = useState(addState ?? "listCard") // new:add buttons; deal: select deal; add: add quantity
  const [selectedQuantity, setSelectedQuantity] = useState(
    quantity > 0 ? quantity : ""
  )
  
  const quantityValidation = useQuantityValidation()
  
  //const [selectedVolumeDiscountPercent, setSelectedVolumeDiscountPercent] = useState
  const [inputColor, setInputColor] = useState()
  const [volumeDiscount, setVolumeDiscount] = useState(0)
  const [modifyBasketButton, setModifyBasketButton] = useState(
    quantity ? "Adaugat in cos" : "Confirma Cantitatea"
  )
  const [alreadyTriedToAddBasket, setAlreadyTriedToAddBasket] = useState(false)
  const timeout1 = useRef()
  const inputRef = useRef()
  const size = useWindowSize()


  const dispatch = useDispatch()
  const history = useHistory()
  const isLogged = useIsLogged()
  // const companyType = useSelector(state => state.company?.type) //moved to useUserInfo()
  const hasFastOptions = fastOptions?.length > 0

  // const createUrlPrefix = ({customUrl, customTags}) => `${customUrl}`
  //                         ?.concat?.(customTags?.length ? "-":"")
  //                         ?.concat?.(customTags?.join?.("-"));
  const quantityZeroError = () => {
    printError("addToBasket()", "quantity should me more than 0")
    toastr.error("Eroare: cantitatea nu poate fi 0")
  }
  const goToDetails = () =>
    !noImage && goToProductDetails(id, customTags, customUrl)

  // const clientType = !authenticatedState ? "GUEST" : "LARGE"
  const clientType = inputClientType ?? companyType
  if (clientType === "GUEST") className += " no-price-guest"
  useEffect(() => {
    quantity && setSelectedQuantity(quantity)
  }, [quantity])

  useEffect(() => {
    lookUpDeal(selectedQuantity)
  }, [selectedQuantity])
  useEffect (() => {
    if(quantity === 0 && selectedQuantity > 0 && cardState === "addToCartCard" && alreadyTriedToAddBasket){
      setSelectedQuantity("")
      setAlreadyTriedToAddBasket(false)
      setModifyBasketButton("Adauga in cos")
    }
  }, [quantity, selectedQuantity, cardState])

  useEffect(() => {
    if (discountVolumeId)
      if (cardState === "addToCartCard")
        dispatch(getVolumeDiscountQuantities(discountVolumeId, id))
  }, [discountVolumeId, cardState])
  // ================ DATA SET-UP ================
  let totalGrossPrice = `${numberFormatter(
    round(
      (priceGross * selectedQuantity * (100 - volumeDiscount)) / 100
    )?.toFixed(2),
    "."
  )} RON (cu TVA)` // (parseInt(youSaveValue) + volumeDiscount > maxDiscount ? maxDiscount-parseInt(youSaveValue) :
  let totalNetPrice = `${numberFormatter(
    round(
      (priceNet * selectedQuantity * (100 - volumeDiscount)) / 100
    )?.toFixed(2),
    "."
  )}` //) (parseInt(youSaveValue) + volumeDiscount > maxDiscount ? maxDiscount-parseInt(youSaveValue) :
  //TODO prepared only - calculate case of maxDiscount if lookDeals is not enough
  let discountedNetPrice = `${numberFormatter(
    round(((priceNet * (100 - volumeDiscount)) / 100)?.toFixed(2)),
    "."
  )}` //(parseInt(youSaveValue) + volumeDiscount > maxDiscount ? maxDiscount-parseInt(youSaveValue) :
  let discountedBrutPrice = `${numberFormatter(
    round(((priceGross * (100 - volumeDiscount)) / 100)?.toFixed(2)),
    "."
  )}` //(parseInt(youSaveValue) + volumeDiscount > maxDiscount ? maxDiscount-parseInt(youSaveValue) :
  const discountPurple = "-2%"
  const discountGreen = null
  let mainSplitPrice = String(mainPrice)?.split?.(".")
  let totalSplitPrice = String(totalNetPrice)?.split?.(".")
  const totalStock = centralStockQuantity + localStockQuantity
  mainSplitPrice[0] = numberFormatter(mainSplitPrice?.[0], ".")
  totalSplitPrice[0] = numberFormatter(totalSplitPrice?.[0], ".")
  totalGrossPrice = numberFormatter(totalGrossPrice, ".")
  totalNetPrice = numberFormatter(totalNetPrice, ".")
  minQuantity = minQuantity ? minQuantity : 1

  const productWithouPrice =
    props?.priceGross === 0 ||
    props.priceGross === null ||
    props.priceGross === undefined

  // ================ ACTIONS ================

  //search if there is a deal for the volume
  const lookUpDeal = (q) => {
    let maxVolDiscount = 0
    deals?.forEach?.((item) => {
      if (item?.quantity <= q && item?.discountValue > maxVolDiscount)
        maxVolDiscount = item?.discountValue
    })
    //TODO handle maxDiscount problem here I guess maxDiscount < yousaveValue + maxVolDiscount
    //then calculate based on listPrice?? listPrice with maxdiscount??
    setVolumeDiscount(maxVolDiscount)
  }
  const calculateMaxAllowedRounded = (q) => {
    const modulo = q % minQuantity
    if(modulo > 0) return q - modulo
    return q
  }
  const modifyQuantity = (newQ, ignoreStock) => {

    
    quantityValidation?.stop?.()


    const roundUp = parseFloatOrZero(parseFloatOrZero((Math.ceil((newQ / minQuantity)?.toFixed?.(5)) * minQuantity))?.toFixed?.(2))
    // const roundUp = Math.ceil(newQ / minQuantity) * minQuantity
    // lookUpDeal(newQ)
    setSelectedQuantity(newQ)
    clearTimeout(timeout1.current)
    if (quantity) setModifyBasketButton("Confirma Modificarea")
    //round up for min Q
    if (minQuantity && roundUp != newQ) {
      quantityValidation?.start?.()
      timeout1.current = setTimeout(() => {
        if (roundUp > totalStock) {
          setSelectedQuantity(calculateMaxAllowedRounded(totalStock))
        } else {
          setSelectedQuantity(minQuantity ? roundUp : newQ)//roundUp?.toFixed(2)
        }
        // lookUpDeal(minQuantity ? roundUp : newQ)
        setInputColor("orange")
        quantityValidation?.stop?.()

        setTimeout(() => {
          setInputColor()
        }, 4000)
      }, 1000)
    }
    //scale down for max stock
    const maxStock = localStockQuantity + centralStockQuantity
    if (newQ > maxStock && !ignoreStock) {
      quantityValidation?.start?.()
      timeout1.current = setTimeout(() => {
        setSelectedQuantity(calculateMaxAllowedRounded(maxStock))
        // lookUpDeal(maxStock)
        setInputColor("red")
        quantityValidation?.stop?.()
        setTimeout(() => {
          setInputColor()
        }, 4000)
      }, 1000)
    }
  }

  const setPriceDetail = () => setCardState("priceCard")
  const addToCartAction = (quantity) => {
    // dispatch(addToCart(item, quantity ?? selectedQuantity));
    closeModal && closeModal() //we call it ONLY is used as an edit modal for long cards
    dispatch(resetCrtList()) //otherwise modal for accessories won't work properly
    dispatch(setPageModalData()) //otherwise modal for accessories won't work properly
    if (parseFloatOrZero(selectedQuantity) <= 0) {
      return quantityZeroError()
    }
    else if(item?.hasObligatoryAccessory) {
      setTimeout(() => {
        dispatch(
          loadItem(
            "product/goods/shop",
            item?.id,
            false,
            false,
            loadAccessories,
            selectedQuantity ?? quantity
          )
        )
        dispatch(setPageModal("add-related-products-to-basket"))
        dispatch(
          setPageModalData({ ...item, quantity: selectedQuantity ?? quantity })
        )
      }, 50)
    } else {
      dispatch(
        isBasketPage
        ? modifyBasket(item?.id, quantity ?? selectedQuantity, priceNet)
        : addToBasket(item?.id, quantity ?? selectedQuantity, priceNet, "addingToBasket")
        )
    }
    setAlreadyTriedToAddBasket(true)
    setCardState("listCard")
    setModifyBasketButton("Adaugat in cos")
    //setTimeout(() => {window.location.reload(true);},500)
  }

  const addToListAction = () => {
    dispatch(setPageModal("addToList"))
    dispatch(setPageModalData({ ...item, quantity: selectedQuantity }))
    setCardState("listCard")
  }
  const updateListQuantity = () => {
    dispatch(
      updateListQuantityAction({ productId: id, quantity: selectedQuantity })
    )
    setCardState("listCard")
    closeModal && closeModal() //we call it ONLY is used as an edit modal for long cards
  }

  const saveInListAction = () => {
    // dispatch(setPageModalData({...item, quantity: selectedQuantity}));
    setCardState("listCard")
    closeModal && closeModal() //we call it ONLY is used as an edit modal for long cards
  }

  const remindeMeAction = () => {
    productWithouPrice
      ? dispatch(
          savePriceRequest({
            props,
            selectedQuantity,
            status: "NEW",
            companyName: crtFormItem?.companyName,
            companyId: crtFormItem?.organizationId,
            companyType: crtFormItem?.companyType
          })
        )
      : dispatch(
          saveStockRequest({
            props,
            selectedQuantity,
            status: "NEW",
            companyName: crtFormItem?.companyName,
            companyId: crtFormItem?.organizationId,
            companyType: crtFormItem?.companyType
          })
        )

    setCardState("listCard")
    closeModal && closeModal()
  }

  const deleteFromCartAction = () => {
    dispatch(modifyBasket(item?.id, 0))
    setModifyBasketButton("Adauga in cos")
    setCardState("listCard")
    setSelectedQuantity("")
    closeModal && closeModal() //we call it ONLY is used as an edit modal for long cards
  }

  // ====================== BUTTONS ===================

  const deleteFromListButton = (
    <MagicButton2
      className="red"
      type="grey"
      h={8}
      svg="Trash"
      popBottom={translate?.("productCard.delete")}
      onClick={() => dispatch(deleteProductFromList(list, id))}
    />
  )
  const addToCartButtonSquare = (
    <MagicButton
      // className="red"
      // type="grey"
      disabled={noStock}
      h={8}
      svg="cartadd"
      onClick={() => {
        setCardState("addToCartCard")
        setShowModal(true)
      }}
    />
  )

  const isSeeDetailsBtnDisabled =
    isLogged && !userPermissions?.includes?.("BASKET_WRITE")

  const listCardButtons = (
    <>
      {noStock || productWithouPrice ? (
        clientType === "GUEST" ? (
          // ? nullStock
          noImage ? null : (
            <MagicButton2
              h={8}
              mb={1}
              w={100}
              // type="black"
              label={<Lang value="productcard.seeDetails" />}
              url={productDetailHref}
              onClick={() => {
                setScrollPosition()
                setTimeout(() => goToDetails(), 1)
              }} //goToDetails()}
              // className={clientType === 'SMALL' && noStock ? "disabled" : ""}
            />
          )
        ) : (
          true && (
            <MagicButton2 //!window?.location?.pathname?.includes?.('/shop/details/')
              h={8}
              mb={1}
              w={100}
              type="black"
              label={
                productWithouPrice ? (
                  <Lang value="productcard.askPrice" />
                ) : (
                  <Lang value="productcard.returns" />
                )
              }
              onClick={() => setCardState("remindeMeCard")}
              // className={clientType === 'SMALL' && noStock ? "disabled" : ""}
              disabled={!userPermissions?.includes?.("BASKET_WRITE")}
            />
          )
        )
      ) : noImage && clientType === "GUEST" ? null : (
        <MagicButton
          h={8}
          mb={1}
          p={1}
          label={getCTAtext(clientType)}
          url={clientType === "GUEST" ? productDetailHref : undefined}
          onClick={() => {
            if (clientType === "GUEST") {
              setScrollPosition()
            } else {
              dispatch(setLastAddedToCart(item?.id))
              setCardState("addToCartCard")
            }
          }}
          className={clientType === "SMALL" && noStock ? "disabled" : ""}
          disabled={isSeeDetailsBtnDisabled}
        />
      )}
      {clientType !== "GUEST" && (
        <MB flex mt={1}>
          {/* {clientType === "LARGE" ? (
            <MagicButton
              h={8}
              p={1}
              label="Comanda acum. Platesti in 30 de zile"
              outline
            />
          ) : ( */}
          {!noListButton && (
            <MagicButton
              h={8}
              p={1}
              label={<Lang value="productcard.saveList" />}
              type="grey-blue"
              onClick={() => setCardState("addToListCard")}
            />
          )}
          {clientType === "RESELLER" && !noListButton && (
            <MagicButton h={8} p={0} ml={1} type="grey-blue" svg="Services38" />
          )}
        </MB>
      )}
    </>
  )

  const closeButton = (
    <img 
      data-test-id='vyN0'
      onContextMenu={(e) => e?.preventDefault?.()}
      src={Close}
      className="close round-clear"
      alt="clear"
      onClick={() => (closeModal ? closeModal() : setCardState("listCard"))}
    />
  )

  // =============== product images section ==============
  const productImageSection = !noImage && (
    <Link className="image-rating-section mb4" to={productDetailHref}>
      {images?.length > 0 ? (
        imageChain3({ images, imageLimit: 7, size: 40, imgPerLine: 4 })
      ) : (
        <div data-test-id='hBLb' className={`img-wrapper ${clientType}`}>
          <img 
            data-test-id='u0jP'
            onContextMenu={(e) => e?.preventDefault?.()}
            src={attachSizeQueryParamsSmall(itemImage)}
            className="image click"
            alt="img"
            onClick={setScrollPosition}
          />
        </div>
      )}
    </Link>
  )
  // =========================================================
  const stockInfoSection = stockInfo?.map?.((item, index) => (
    <div data-test-id='D2Mg' className={`flex alignCenter mt2`} key={index}>
      <SvgIcon name={item?.icon} />
      <div data-test-id='U43u' className={` ml2 ${item?.className}`}>{item?.message}</div>
      {item?.infoMessage && (
        <InfoIcon className="ml2" popBottom={item?.infoMessage} />
      )}
    </div>
  ))
  // ==========DESIGNED FOR PROD DETAILS ======================================
  const getCategoryString = () => {
    let returnString = ""
    const categoryArray = convertCategoryTreeToList(props?.category)
    categoryArray?.forEach?.((i, index) => {
      if (index === 0) returnString += `În categoria: ${i?.name}`
      else returnString += `în subcategoria: ${i?.name}`
      if (index < categoryArray?.length) returnString += ", "
      else returnString += "."
    })
    return returnString
  }
  const categoryStringWithBadge = (
    <div data-test-id='KJt5' className="mt3 mb4">
      {/* {badgeTitle && <span data-test-id='atfL' className={`detailBadge ${clientType}`}>{badgeTitle}</span>} */}
      <span data-test-id='k7vo' className="ptype-5">{getCategoryString()}</span>
    </div>
  )

  // =========================================================
  const totalPriceGreySection = ({ wQuantity, right, showPrimaryPrice }) => (
    <div 
      data-test-id='aKNC'
      className={`total-price ptype-5-strong ${!wQuantity ? "center" : ""} `}
    >
      {wQuantity && (
        <div data-test-id='t6Xj' className="left">
          <span data-test-id='w0f6' className="quantity mr1">{selectedQuantity}</span>
          <span data-test-id='fAZv' className="unit">{unitOfMeasure}</span>
        </div>
      )}
      {priceSection({
        right,
        criticalSection: true,
        primaryPrice: totalNetPrice,
        secondaryPrice: totalGrossPrice,
        bottom: true,
        showPrimaryPrice
      })}
    </div>
  )

  // =========================================================

  const priceSection = ({
    className,
    discountRed,
    flex,
    primaryPrice,
    secondaryPrice,
    forcedSecondaryPrice,
    listPrice,
    right,
    criticalSection,
    priceDetailCard,
    bottom,
    strikeThrough,
    longCard,
    showPrimaryPrice
  }) => {
    if (clientType === "GUEST") return null
    const showVolDiscount =
      volumeDiscount > 0 &&
      parseInt(youSaveValue) + volumeDiscount <= maxDiscount //!flex &&
    const showMaxDiscount =
      volumeDiscount > 0 &&
      parseInt(youSaveValue) + volumeDiscount > maxDiscount //!flex &&
    const showGrayDiscount =
      ["FIX", "GRID"]?.includes?.(priceDiscountType) || youSave //&& !showMaxDiscount //!flex selectedQuantity &&
    /**
     * the next line is bug, there is the possibility of productWithoutPrice being false (the product has a gross price,
     * this should change the card state to price request)
     * but the primary price is falsy (assume that price gross is 42 and primary price is undefined),
     * the primary price changes to totalNetPrice which in some cases is Nan, the FormatComponent
     * is buggy as well 
     */
      primaryPrice = primaryPrice ? primaryPrice : totalNetPrice


    const mainPriceFragment = (
      <>
        <div data-test-id='KSZ1' className={`mr1 ${!flex ? "ptype-5-strong" : "ptype-6"}`}>
          <FormatComponent
            number={
              volumeDiscount && !priceDetailCard && !showPrimaryPrice
                ? discountedNetPrice
                : primaryPrice
            }
            productCardRelated={criticalSection}
          />
        </div>

        <div data-test-id='8jJ5' className={`${!flex ? "ptype-5-strong" : "ptype-6"}`}>
          {finalCurrency}
        </div>

        {/* {flex && /[+-]?([0-9]*[.])?[0-9]?[0-9] %/.test(youSave) ? <span data-test-id='bPZb' className="ml1 mt1 small-red-tag">{youSave}</span> : ""}
        {showRedDiscount ? <span data-test-id='VHKx' className="ml1 mt1 small-red-tag">{youSave}</span> : ""}
        {showVolDiscount && <span data-test-id='8cwM' className="ml1 mt1 small-purple-tag">-{volumeDiscount}%</span>}
        {showMaxDiscount && <span data-test-id='s4XR' className="ml1 mt1 small-green-tag">-{maxDiscount}% MAX</span>} */}
      </>
    )

    const processedSecondaryPrice =
      /.[0-9](RON)/.test(secondaryPrice) || /.[0-9](LEI)/.test(secondaryPrice)
        ? secondaryPrice?.slice?.(0, -12)
        : secondaryPrice?.includes?.("RON") || secondaryPrice?.includes?.("LEI")
          ? secondaryPrice?.slice?.(0, -13)
          : secondaryPrice?.includes?.("null")
            ? secondaryPrice?.slice?.(0, -14)
            : secondaryPrice?.slice?.(0, -9)

    const secPriceFragment = (
      <div 
        data-test-id='WPbk'
        className={`ptype-3 text-light-02 ${flex ? "ml2" : "mt1"} ${
          right ? "text-right" : ""
        } ${!flex && !right ? "text-center" : ""}`}
      >
        <FormatComponent
          number={
            volumeDiscount && !priceDetailCard && !showPrimaryPrice
              ? discountedBrutPrice
              : (forcedSecondaryPrice || processedSecondaryPrice)
          }
          suffixText={computed.suffixText.secondary}
          productCardRelated={criticalSection}
        />
      </div>
    )

    // listPrice =
    // /.[0-9](RON)/.test(listPrice) || /.[0-9](LEI)/.test(listPrice)
    //   ? listPrice?.slice?.(0, -12)
    //   : listPrice?.includes?.("RON") || listPrice?.includes?.("LEI")
    //   ? listPrice?.slice?.(0, -13)
    //   : listPrice?.includes?.("null")
    //   ? listPrice?.slice?.(0, -14)
    //   : listPrice?.slice?.(0, -9)

    const listPriceFragment = //${flex ? "ml2" : "mt1"} ${right ? 'text-right' : ''} ${!flex && !right ? 'text-center' : ''
      (
        <div data-test-id='wAao' className={`ptype-3 text-light-02 }`}>
          <FormatComponent
            number={listPrice}
            suffixText={finalCurrency}
            productCardRelated={criticalSection}
          />
        </div>
      )
    return (
      <>
        <div 
          data-test-id='hcok'
          className={`price-fragment ${className ?? ""} ${
            flex ? "flex alignEnd" : ""
          }`}
          style={strikeThrough && { textDecoration: "line-through" }}
        >
          <div data-test-id='Eadj' className="flex ab text-light-01">
            {mainPriceFragment}
            {longCard && (
              <>
                {/*flex && /[+-]?([0-9]*[.])?[0-9]?[0-9] %/.test(youSave) ? <span data-test-id='W3qR' className="ml1 mt1 small-red-tag">{youSave}</span> : ""*/}
                {showGrayDiscount && priceDiscountType === "GRID" ? (
                  <span data-test-id='upfw' className="ml2 mt1 small-red-tag">{youSave}</span>
                ) : (
                  ""
                )}
                {showVolDiscount && (
                  <span data-test-id='vYh6' className="ml2 mt1 small-purple-tag">
                    -{volumeDiscount}%
                  </span>
                )}
                {showMaxDiscount && (
                  <span data-test-id='9uQM' className="ml2 mt1 small-green-tag">
                    -{maxDiscount}% MAX
                  </span>
                )}
                {!isBasketPage && renderedBy !== "userList" &&
                  <InfoIcon
                      className="ml2"
                      popBottom={translate?.("productcard.seeDetailsPrice")}
                      color={inputColor}
                      onClick={() => {
                        setPriceDetail()
                        longCard && setShowModal(true)
                      }}
                  />
                }
                
              </>
            )}
          </div>
          {secPriceFragment}
          {!priceDetailCard && !bottom && !longCard && !noImage && !isBasketPage && renderedBy !== "userList" && (
            <InfoIcon
              popBottom={translate?.("productcard.seeDetailsPrice")}
              color={inputColor}
              onClick={() => {
                setPriceDetail()
                longCard && setShowModal(true)
              }}
            />
          )}
        </div>
        {/* {flex && /[+-]?([0-9]*[.])?[0-9]?[0-9] %/.test(youSave) ? <span data-test-id='XZPD' className="ml1 mt1 small-red-tag">{youSave}</span> : ""} */}
        {/* {showRedDiscount ?  */}
        {!priceDetailCard && !bottom && !noImage && (
          <div data-test-id='LlDd' className="flex column alignStart">
            {showGrayDiscount && !longCard && renderedBy !== "userList" &&(
              <div data-test-id='XA7H' className="flex">
                <span data-test-id='5Sjd' className="ptype-5-strike-normal text-light-02">
                  {priceDiscountType === "GRID" && listPriceFragment}
                </span>
                <span data-test-id='lO2Y' className="ml1 mt1 new-card-grey-tag">
                  {priceDiscountType === "GRID"
                    ? `${youSave} discount - pretul tau`
                    : "Prețul tău final"}
                </span>
              </div>
            )}
            {showVolDiscount && !longCard && renderedBy !== "userList" &&(
              <div data-test-id='liIM' className="flex">
                <span data-test-id='E8XF' className="ptype-5-strike-normal text-light-02">
                  <FormatComponent
                    formatStyle=" ptype-3 text-light-02"
                    number={mainPrice}
                    suffixText={finalCurrency}
                    productCardRelated={criticalSection}
                  />
                </span>
                <span data-test-id='jBXC' className="ml1 mt1 new-card-purple-tag">
                  {volumeDiscount}% discount de volum
                </span>
              </div>
            )}
            {showMaxDiscount && !longCard && renderedBy !== "userList" &&(
              <div data-test-id='iVRW' className="flex">
                <span data-test-id='UXW4' className="ptype-5-strike-normal text-light-02 flex">
                  <FormatComponent
                    formatStyle=" ptype-3 text-light-02"
                    number={mainPrice}
                    suffixText={finalCurrency}
                    productCardRelated={criticalSection}
                  />
                  <span data-test-id='NDwP' className="ml1 mt1 new-card-purple-tag">
                    {volumeDiscount}% discount de volum
                  </span>
                </span>
                <span data-test-id='UsRn' className="ml1 mt1 new-card-red-tag">
                  {maxDiscount}% discount maxim
                </span>
              </div>
            )}
          </div>
        )}
      </>
    )
  }
  // =============== brand and title section    ==============

  const productCodeStyle = {
    backgroundColor: "#f3f3f3",
    borderRadius: "11px",
    padding: "2px 9px",
    marginRight: "8px"
  }

  const brandTitle = (
    <>
      <div data-test-id='nF9N' className="mb1">
        {isLogged &&
          <span data-test-id='2Fid' className="ptype-4" style={productCodeStyle}>
            {props?.code}
          </span>
        }
        <span data-test-id='Hpah' className="ptype-4-price text-light-02 ">
          {brandName && `Brand: ${brandName}`}
        </span>
      </div>
      <MyLink
        className={`title ${noImage ? "" : "click"}`}
        onClick={setScrollPosition}
        to={productDetailHref}
      >
        {title}
      </MyLink>
    </>
  )

  // =========================================================
  const listCardTopSection = (
    <div data-test-id='IzRi' className="mb3">
      {badgeTitle && cardState === "listCard" && !noImage && (
        <div data-test-id='LkXU' className={`badge ${clientType}`}>{badgeTitle}</div>
      )}
      {productImageSection}
      <div data-test-id='p7SR' className="gray-line"></div>
      <div data-test-id='31Nj' className="flex">
        {brandName && (
          <div data-test-id='dRUK' className="ptype-4-price text-light-02 mt2 flex alignCenter">{`${
            noImage && clientType === "GUEST" ? "BRAND: " : ""
          } ${brandName}`}</div>
        )}
        {clientType !== "GUEST" && (
          <ProductRating rating={ratingAverage} reviews={ratingCount} />
        )}
      </div>

      {isProductDetailPage ? (
        <div data-test-id='gzqK' className="title">{title}</div>
      ) : (
        <MyLink
          to={productDetailHref}
          className={`title ${noImage ? "" : "click"}`}
          onClick={setScrollPosition}
        >
          {title}
        </MyLink>
      )}

      {noImage && clientType === "GUEST" && categoryStringWithBadge}

      {/* {clientType !== "GUEST" && (
        <ProductRating rating={ratingAverage} reviews={ratingCount} />
      )} */}
      <div data-test-id='nMH3' className="stock">{clientType !== "GUEST" && stockInfoSection}</div>
      {(cardState !== "predefinedLists"
          && cardState !== "excelCard")
          && (
            <>
              <div data-test-id='ysxX' className="gray-line"></div>
              {priceSection({
                className: "my2",
                flex: true,
                criticalSection: false,
                primaryPrice: String(mainPrice)?.includes?.("LEI")
                  ? String(mainPrice)?.slice?.(0, -4)
                  : mainPrice,
                secondaryPrice: secondaryPrice,
                forcedSecondaryPrice: computed.prices.secondary,
                listPrice
              })}
            </>
      )}
      {/* {(clientType === "RESELLER" || clientType === "LARGE") && youSave && (
      <div data-test-id='a27h' className="rebate">{rebateMessage}</div>
    )} */}
      {/* {clientType !== "GUEST" && stockInfoSection} */}
    </div>
  )
  // =========================================================
  const quantityInputSection = ({ ignoreStock }) => (
    <>
      {/* <div data-test-id='CjcB' className="flex-sb"> */}
        <div data-test-id='Ulz4' className="flex-sb">
          <div data-test-id='TDLC' className="ptype-4-price button-secondary">
            <Lang value="productcard.chooseAmount" />
          </div>
          { smallPackageQuantity && 
            smallPackageType &&
              <div data-test-id='jdQm' className="ptype-4-price button-secondary color-text-link" style={{textTransform: "uppercase"}}>
                {/* <Lang value="productcard.chooseAmount" /> style={{ color: "#005CB9"}}*/}
                {`${smallPackageQuantity} / ${smallPackageType}`}
              </div>
          }
        </div>
        {/* {deals?.length > 0 && ( */}
          {/* <div data-test-id='fg4s' className="flex alignCenter">
            {specialPriceSvg}
            <div 
              data-test-id='rJpD'
              className="ptype-4-price btn-txt-purple-60 ml1"
              onClick={() => setCardState("specialPricesCard")}
            >
              <Lang value="productcard.priceSpecial" />
            </div>
          </div> */}
        {/* )} */}
      {/* </div> */}
      <div 
        data-test-id='rGfm'
        className={`input-zone ${inputColor ?? ""} ${
          hasFastOptions ? "" : "pr16"
        }`}
      >
        {minQuantity > 0 && (
          <div data-test-id='eDNy' className="min-q">
            <div data-test-id='SjBX'>{minQuantity}</div>
            <InfoIcon
              popBottom={translate?.("productCard.min", {
                Variable21: minQuantity  || '0',
                Variable22: unitOfMeasure || ''
              })}
              color={inputColor}
            />
          </div>
        )}
        <div data-test-id='lacV' className="pc-quantity ptype-5-strong">
          <input 
            data-test-id='lTqs'
            autoFocus={
              (
              size?.width < 650
                ?
              false
                :
              lastAddedToCartId === item?.id
              )
          }
            className="ptype-5-strong"
            value={selectedQuantity}
            placeholder="0"
            type="number"
            min="0"
            onChange={(e) => modifyQuantity(e?.target?.value, ignoreStock)}
            onBlur={() => size?.width < 650 && hideMobileKeyboard(inputRef)}
            ref={inputRef}
          />
          <div data-test-id='33Jq' className="unit text-light-02">{unitOfMeasure}</div>
        </div>
        {hasFastOptions && (
          <MagicButton2
            h={7.8}
            svg="newfastoptions"
            clear
            className="clearStroke"
            onClick={() => setCardState("fastPickerCard")}
          />
        )}
        {/** numbers icon was the old svg*/}
      </div>
    </>
  )
  // =========================================================
  const listModifyBottomSection = (
    <>
      {totalPriceGreySection({ wQuantity: true, right: true })}
      <div data-test-id='w5xX' className="flex mt2">
        <MagicButton
          h={8}
          mr={1}
          p={1}
          label={modifyBasketButton}
          type="green"
          onClick={() => setCardState("addToCartCard")}
        />
        <MagicButton
          className="red"
          type="grey"
          h={8}
          svg="Trash"
          onClick={() => deleteFromCartAction()}
        />
      </div>
    </>
  )

  const longCardMainButtons = (
    <div data-test-id='05nt' className="flex ">
      {clientType === "GUEST" ? (
        <MagicButton2
          h={6}
          mb={0}
          w={100}
          // type="black"
          label={"Vezi Detalii"}
          url={productDetailHref}
          onClick={setScrollPosition}
          // className={clientType === 'SMALL' && noStock ? "disabled" : ""}
        />
      ) : noStock || productWithouPrice ? (
        <MagicButton2
          h={6}
          mb={0}
          w={100}
          type="black"
          label={
            productWithouPrice ? (
              <Lang value="productcard.askPrice" />
            ) : (
              <Lang value="productcard.returns" />
            )
          }
          //onClick={() => alert("set stock reminder")}
          //onClick={() => setCardState('remindeMeCard')}
          onClick={() => {
            if (modifyBasketButton !== "Adaugat in cos") {
              setCardState("remindeMeCard")
              setShowModal(true)
            }
          }}
          // className={clientType === 'SMALL' && noStock ? "disabled" : ""}
        />
      ) : (
        <MagicButton
          h={6}
          mb={0}
          p={1}
          mr={quantity && 1}
          // type={modifyBasketButton === "Adaugat in cos" ? "green" : undefined}
          disabled={
            modifyBasketButton === "Adaugat in cos" ||
            !userPermissions?.includes?.("BASKET_WRITE")
          }
          label={<Lang value="productcard.addCart" />}
          onClick={() => {
            if (modifyBasketButton !== "Adaugat in cos") {
              dispatch(setLastAddedToCart(item?.id))
              setCardState("addToCartCard")
              setShowModal(true)
            }
          }}
        />
      )}
      {!list && isLogged && (
        <MagicButton
          h={6}
          ml={1}
          p={1}
          type="grey"
          svg="AddToList"
          onClick={() => {
            //addToListAction()
            setCardState("addToListCard")
            setShowModal(true)
          }}
        />
      )}
      {/* {list && deleteFromListButton} */}
    </div>
  )
  // ===============================================

  const isAddedToCartButton = modifyBasketButton === "Adaugat in cos"
  const isAddToCartButtonDisabled = 
  
    isAddedToCartButton || 
    (loadingIndicators?.addingToBasket && !nrOfProductsInCart) ||
    quantityValidation?.isInProgress


  
  const addToCartOnClick = () => {
    if(isAddedToCartButton) return
    if(modifyBasketButton !== "Confirma Modificarea") return addToCartAction()
    return setTimeout(addToCartAction, 0)
  }
  const addToCartBottomSection = (
    <div data-test-id='VRia'>
      {quantityInputSection({})}
      {totalPriceGreySection({ wQuantity: false, showPrimaryPrice: true })}
      <div data-test-id='Nt3K' className="flex">
        <MagicButton
          h={8}
          mb={0}
          p={1}
          mr={quantity && 1}
          type={isAddedToCartButton ? "green" : undefined}
          disabled={isAddToCartButtonDisabled}
          label={modifyBasketButton}
          onClick={addToCartOnClick}
          // loadingId={"addingToBasket"}
        />
        {quantity ? (
          <MagicButton
            className="red"
            type="grey"
            h={8}
            svg="Trash"
            onClick={() => deleteFromCartAction()}
          />
        ) : null}
      </div>
      {/* :<MagicButton h={8} mb={1} p={1} label={modifyBasketButton} onClick={() => addToCartAction()}/> */}
    </div>
  )
{/* MODAL CARD FOR EXCEL MODIFY QUANTITY ONLY */}
const modifyExcelQuantitySection = (
  <div data-test-id='Px2K'>
    {quantityInputSection({})}
    {totalPriceGreySection({ wQuantity: false, showPrimaryPrice: true })}
    <div data-test-id='C11g' className="flex">
      <MagicButton
        h={8}
        mb={0}
        p={1}
        mr={quantity && 1}
        label={"Modifica cantitatea pentru excel"}
        onClick={() =>
          {
            dispatch(setExcelQuantity(id, selectedQuantity))
            closeModal && closeModal()
          }
        }
      />
    </div>
  </div>
)

  const mainCardLayout = (
    <>
      {cardState !== "listCard" && closeButton}
      {listCardTopSection}

      <div data-test-id='oJyg'>
        {cardState === "listCard"
          ? quantity > 0 && clientType !== "GUEST"
            ? addToCartBottomSection
            : listCardButtons
          : // ? quantity > 0 ? listModifyBottomSection : listCardButtons
          cardState === "excelCard"
            ? modifyExcelQuantitySection
            : addToCartBottomSection}
      </div>
    </>
  )

  const generalEditCardLayout = (
    <>
      {cardState !== "predefinedLists" && closeButton}
      {listCardTopSection}

      <div data-test-id='D8Ng'>
        {cardState === "addToListCard" && (
          <>
            {quantityInputSection({ ignoreStock: true })}
            {totalPriceGreySection({ wQuantity: false })}
            {/* if we are in the user's list, we need tu update quantity, not to add to a new lithis.state. */}
            {renderedBy === "userList" ? (
              <MagicButton
                h={8}
                mb={0}
                p={1}
                label={<Lang value="productcard.save" />}
                onClick={updateListQuantity}
              />
            ) : (
              <MagicButton
                h={8}
                mb={0}
                p={1}
                disabled={false}
                label={<Lang value="productcard.chooseList" />}
                onClick={addToListAction}
              />
            )}
          </>
        )}
        {cardState === "predefinedLists" && (
          <>
            {quantityInputSection({})}
            {/* {totalPriceGreySection({})} */}
            <MagicButton
              h={8}
              mb={0}
              p={1}
              disabled={false}
              label={<Lang value="productcard.addCart" />}
              onClick={() => addToCartAction(selectedQuantity)}
            />
            {/*{() => dispatch(modifyBasket(item?.id, selectedQuantity))} */}
          </>
        )}
        {cardState === "saveInListCard" && (
          <>
            {quantityInputSection({ ignoreStock: true })}
            {totalPriceGreySection({ wQuantity: false })}
            <MagicButton
              h={8}
              mb={0}
              p={1}
              disabled={false}
              label={<Lang value="productcard.save" />}
              onClick={saveInListAction}
            />
          </>
        )}
        {cardState === "remindeMeCard" && (
          <>
            {quantityInputSection({ ignoreStock: true })}
            {!productWithouPrice && totalPriceGreySection({ wQuantity: false })}
            <MagicButton
              h={8}
              mb={0}
              p={1}
              disabled={false}
              label={
                productWithouPrice ? (
                  <Lang value="productcard.askPriceAmount" />
                ) : (
                  <Lang value="productcard.confirmAmount" />
                )
              }
              onClick={remindeMeAction}
            />
          </>
        )}
      </div>
    </>
  )

  // ===============================================
  // Price detail change request card and related cards for different type of discounts
  const whiteDetailCard = (title, strikeThrough, type) => {
    const netPriceToShow = type === "discount" ? mainPrice : listPrice
    const brutPriceToShow =
      type === "discount" ? secondaryPrice : secondaryListPrice
    return (
      <div data-test-id='VDtb' className="mt4">
        <div data-test-id='oklu' className="ptype-4-price text-light-02">{title}</div>
        <div data-test-id='PdHA' className="gray-line mt2"></div>
        {priceSection({
          className: "my2",
          flex: true,
          criticalSection: false,
          primaryPrice: String(netPriceToShow)?.includes?.("LEI")
            ? String(netPriceToShow)?.slice?.(0, -4)
            : netPriceToShow,
          secondaryPrice: brutPriceToShow,
          priceDetailCard: true,
          strikeThrough
        })}
        {type === "mockpack" && (
          <div data-test-id='eA9V' className="mt1 new-card-red-tag-wide">
            <Lang value="productcard.78Discount" />
          </div>
        )}
        {type === "volume" && (
          <div data-test-id='l3Cn' className="mt1 new-card-purple-tag-wide">
            <Lang value="productcard.mockDiscount" />
          </div>
        )}
        {type === "discount" && (
          <div data-test-id='10O5' className="mt1 new-card-grey-tag-wide">
            {youSave} discount - pretul tau
          </div>
        )}
      </div>
    )
  }

  const blueDetailCard = (title, type) => (
    <div data-test-id='uh8v' className="already-negociated mt4">
      <div data-test-id='7JC5' className="flex spaceBetween">
        <div data-test-id='ek6R' className="ptype-4-price text-light-02">{title}</div>
        <div data-test-id='8Jfy' className="ptype-4-price blue-right">
          <Lang value="productcard.priceCurrent" />
        </div>
      </div>
      {priceSection({
        className: "my2",
        flex: true,
        criticalSection: false,
        primaryPrice: String(
          volumeDiscount ? discountedNetPrice : mainPrice
        )?.includes?.("LEI")
          ? String(volumeDiscount ? discountedNetPrice : mainPrice)?.slice?.(
              0,
              -4
            )
          : volumeDiscount
          ? discountedNetPrice
          : mainPrice,
        secondaryPrice: volumeDiscount ? discountedBrutPrice : secondaryPrice,
        priceDetailCard: true
      })}
      {/* <div data-test-id='Dlnh' className="flex column alignStart"> */}
      {type === "red" && (
        <div data-test-id='W109' className="mt1 new-card-red-tag-wide">
          <Lang value="productcard.78Discount" />
        </div>
      )}
      {type === "purple" && (
        <div data-test-id='6yUO' className="mt1 new-card-purple-tag-wide">
          {volumeDiscount} discount de volum
        </div>
      )}
      {type === "grey" && (
        <div data-test-id='HdQT' className="mt1 new-card-grey-tag-wide">
          {youSave} discount - pretul tau
        </div>
      )}
      {type === "grey-fix" && (
        <div data-test-id='wkWd' className="mt1 new-card-grey-tag-wide">
          <Lang value="productcard.yourPriceFinal" />
        </div>
      )}
      {/* </div> */}
    </div>
  )

  const priceCard = (
    <div data-test-id='glBt' className="price-card flex column">
      <div data-test-id='TyVc' className="back-button-wrapper">
        <img 
          data-test-id='rhW7'
          className="back-button"
          onContextMenu={(e) => e?.preventDefault?.()}
          src={Left}
          alt=""
          onClick={() => {
            setCardState("listCard")
            closeModal && closeModal()
          }}
        />
      </div>
      <div data-test-id='eacz' className="ptype-6 text-light-01 mt3">
        Detalierea<br data-test-id='cVj1'></br>
        Pretului
      </div>
      {priceDiscountType === "NONE" && (
        <div data-test-id='kTt2' className="already-negociated mt4 ptype-3">
          <Lang value="productcard.discountNegotiation" />
        </div>
      )}
      {priceDiscountType === "FIX" &&
        !volumeDiscount &&
        blueDetailCard("PRETUL TAU", "grey-fix")}

      {/*+ fix + voldiscount kell white TODO-correct in case of we'll have this scenario*/}
      {priceDiscountType === "FIX" &&
        volumeDiscount > 0 &&
        whiteDetailCard("PRETUL TAU", true, "discount")}

      {priceDiscountType === "GRID" && whiteDetailCard("PRET DE LISTA", true)}
      {priceDiscountType === "GRID" &&
        volumeDiscount > 0 &&
        whiteDetailCard("PRETUL TAU", true, "discount")}
      {priceDiscountType === "GRID" &&
        !volumeDiscount &&
        blueDetailCard("PRETUL TAU", "grey")}
      {/* TODO blue cards if max discount exceeded */}
      {/* {whiteDetailCard("PRETUL TAU")} */}
      {/*blueDetailCard("PRET PROMOTIONAL", "red")*/}
      {discountVolumeId &&
        volumeDiscount > 0 &&
        blueDetailCard("DISCOUNT DE VOLUM", "purple")}

      {discountVolumeId && (
        <div data-test-id='z3T3' className="flex alignCenter justifyCenter mt4 volume-button">
          {specialPriceSvg}
          <div 
            data-test-id='0FPR'
            className="ptype-4-price btn-txt-purple-60 ml1"
            onClick={() => setCardState("specialPricesCard")}
          >
            <Lang value="productcard.seePriceVolume" />
          </div>
        </div>
      )}
    </div>
  )

  const cardsLocalProps = {
    totalStock,
    setVolumeDiscount,
    setCardState,
    addToCartAction,
    selectedQuantity,
    setSelectedQuantity,
    closeModal
  }

  //========== BIG PRODUCT CARDS ==============
  // const computedCurrency= (i) => ` ${((i?.currency?.toUpperCase?.() === "RON" || i?.currency?.toUpperCase?.() === "LEI") ? 'LEI' : i?.currency)}`
  // const mainPriceCompute = (i) => `${round(i?.priceNet * i?.quantity)}${computedCurrency(i)}`
  // const secondaryPriceCompute = (i) => `${round(i?.priceGross * i?.quantity)} ${computedCurrency(i)} (cu TVA)`

  const bigProductCard = (
    <div data-test-id='w0mG' className={`productCard ${className ?? ""}`}>
      {cardState === "listCard" && mainCardLayout}
      {cardState === "addToCartCard" && mainCardLayout}
      {cardState === "addToListCard" && generalEditCardLayout}
      {cardState === "saveInListCard" && generalEditCardLayout}
      {cardState === "remindeMeCard" && generalEditCardLayout}
      {cardState === "predefinedLists" && generalEditCardLayout}
      {cardState === "priceCard" && priceCard}
      {cardState === "specialPricesCard" && (
        <SpecialPricesCard {...{ ...props, ...cardsLocalProps }} />
      )}
      {/* {cardState === "addToListCard"  && <AddToListCard {...{...props, ...cardsLocalProps}}/>} */}
      {cardState === "fastPickerCard" && (
        <FastPickerCard {...{ ...props, ...cardsLocalProps }} />
      )}
      {cardState === "excelCard" && mainCardLayout}
    </div>
  )

  //========== LONG PRODUCT CARDS ==============
  const longCardRightCTA = (
    <div data-test-id='WisX' className="right-section">
      {clientType !== "GUEST" && !noPrice && (
        <div data-test-id='NAKk' className="">
          {/*flex */}
          {priceSection({
            className: "mb2",
            flex: true,
            primaryPrice: mainPrice,
            secondaryPrice: secondaryPrice,
            listPrice,
            longCard: true
          })}
        </div>
      )}
      {longCardMainButtons}
    </div>
  )

  const longCardRightCtaList = (
    <div data-test-id='SThq' className="right-section_in-list">
      {!noPrice &&
        priceSection({
          className: "mb2",
          primaryPrice: mainPrice,
          secondaryPrice: secondaryPrice
        })}
      {/* {longCardMainButtons} */}
      {addToCartButtonSquare}
      {deleteFromListButton}
    </div>
  )

  const normalLongCardButtons = (
    <>
      <MagicButton
        h={8}
        mr={1}
        px={1}
        w={12}
        label={"Modifica"}
        type="green"
        onClick={() => {
          setShowModal(true)
          setCardState("addToCartCard")
        }}
      />
      {list ? (
        deleteFromListButton
      ) : (
        <MagicButton
          className="red"
          type="grey"
          h={8}
          svg="Trash"
          onClick={() => deleteFromCartAction()}
        />
      )}
    </>
  )
  const longCardBasketOptions = (
    <div data-test-id='1Zr3' className="flex alignCenter">
      <div data-test-id='P4Pq' className="bg-grey-10 p3 text-light-02 ptype-5-strong flex alignCenter mr2">{`${
        quantity ?? ""
      } ${unitOfMeasure ?? ""}`}</div>
      {cardState !== "predefinedLists" && !noPrice && (
        <div data-test-id='N0Al' className="bg-grey-10 p3 text-light-02 mr2">
          {/*flex alignCenter*/}
          {priceSection({
            right: true,
            criticalSection: true,
            primaryPrice: totalNetPrice,
            secondaryPrice: totalGrossPrice,
            longCard: true,
            bottom: true,
            showPrimaryPrice: true
          })}
        </div>
      )}
      {cardState === "predefinedLists" ? (
        clientType !== "GUEST" ? (
          <MagicButton
            h={10}
            svg="cart add"
            onClick={() => dispatch(modifyBasket(item?.id, selectedQuantity))}
          />
        ) : undefined
      ) : (
        normalLongCardButtons
      )}
    </div>
  )

  const longProductCard = (
    <div data-test-id='8rYA' className={`productCard long ${className ?? ""}`}>
      <Link to={productDetailHref} className={`image-wrap-long-card `}>
        <img 
          data-test-id='wmEn'
          onContextMenu={(e) => e?.preventDefault?.()}
          src={attachSizeQueryParamsXS(itemImage)}
          className="image click"
          alt="img"
          onClick={setScrollPosition}
        />
      </Link>
      <div data-test-id='xhnj' className={"flex media-helper"}>
        <div data-test-id='Jxpa' className="middle">
          {brandTitle}
          <div data-test-id='sNqi' className="flex alignCenter mt1 mr4">
            {badgeTitle && (
              <>
                <div data-test-id='GArV' className={`badge-inline bg-${badgeColor}`}>
                  {badgeTitle}
                </div>
                <div data-test-id='0MPz' className={`badge-inline-after bg-${badgeColor}`} />
              </>
            )}
            {clientType !== "GUEST" && (
              <ProductRating rating={ratingAverage} reviews={ratingCount} />
            )}
          </div>
          <div data-test-id='hGjy' className="flex horizontal-stock">
            {clientType !== "GUEST" && stockInfoSection}
          </div>
        </div>
        {/* {!list && (quantity>0 ? longCardBasketOptions : longCardRightCTA)} */}
        {quantity > 0 && clientType !== "GUEST"
          ? longCardBasketOptions
          : cardState === "predefinedLists"
          ? longCardBasketOptions
          : list
          ? longCardRightCtaList
          : longCardRightCTA}
      </div>

      {showModal && (
        <div data-test-id='8EJA' {...{ ref }} className="central-modal-overlay">
          <ProductCard
            addState={cardState}
            closeModal={() => setShowModal(false)}
            product={props}
            {...props}
            long={false}
          />
        </div>
      )}
    </div>
  )

  return long ? longProductCard : bigProductCard
}

const specialPriceSvg = (
  <svg 
    data-test-id='jkcu'
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.85708 12.5713C5.72691 12.5713 4.62213 12.2362 3.68243 11.6083C2.74272 10.9804 2.01032 10.088 1.57782 9.04383C1.14532 7.99969 1.03216 6.85074 1.25265 5.74229C1.47313 4.63384 2.01736 3.61566 2.81651 2.81651C3.61566 2.01736 4.63384 1.47313 5.74229 1.25265C6.85074 1.03216 7.99969 1.14532 9.04383 1.57782C10.088 2.01032 10.9804 2.74272 11.6083 3.68243C12.2362 4.62213 12.5713 5.72691 12.5713 6.85708H13.7142C13.7142 5.50088 13.312 4.17513 12.5585 3.04749C11.8051 1.91985 10.7341 1.04096 9.48117 0.521967C8.22821 0.00297108 6.84948 -0.132822 5.51933 0.13176C4.18919 0.396342 2.96738 1.04942 2.0084 2.0084C1.04942 2.96738 0.396342 4.18919 0.13176 5.51933C-0.132822 6.84948 0.00297108 8.22821 0.521967 9.48117C1.04096 10.7341 1.91985 11.8051 3.04749 12.5585C4.17513 13.312 5.50088 13.7142 6.85708 13.7142V12.5713Z"
      fill="#8A3FFC"
    />
    <path
      d="M5.71538 7.76457L4.23539 6.28458L3.42969 7.09029L5.71538 9.37598L9.71534 5.37602L8.90964 4.57031L5.71538 7.76457Z"
      fill="#8A3FFC"
    />
    <path
      d="M14.5045 14.8588H10.6404L12.5731 10.9935L14.5045 14.8588ZM12.5731 9.14453C12.4669 9.1445 12.3629 9.17403 12.2726 9.22981C12.1823 9.28559 12.1093 9.36543 12.0618 9.46036L9.20471 15.1746C9.16121 15.2617 9.1407 15.3585 9.14512 15.4558C9.14954 15.5531 9.17874 15.6477 9.22995 15.7305C9.28116 15.8134 9.35269 15.8817 9.43774 15.9292C9.5228 15.9766 9.61857 16.0016 9.71596 16.0016H15.4302C15.5245 16.0016 15.6174 15.9783 15.7005 15.9337C15.7836 15.8891 15.8544 15.8246 15.9065 15.746C15.9587 15.6674 15.9905 15.5771 15.9993 15.4832C16.008 15.3893 15.9934 15.2947 15.9567 15.2078L13.0843 9.46036C13.0368 9.36543 12.9638 9.28561 12.8735 9.22983C12.7832 9.17404 12.6792 9.14451 12.5731 9.14453Z"
      fill="#8A3FFC"
    />
  </svg>
)

export default withLocalize(ProductCard)
