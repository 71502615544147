import { parseFloatOrDefault, parseFloatOrThrow, roundOrThrow } from "utils/numberUtils"
import { defaultCurrencyCode } from "./currencyUtils"


export const getComputedPriceInfo = (product, shouldIgnoreQuantity = false) => {

  const finalCurrency = product?.currency || product?.currencyCode || defaultCurrencyCode
  
  const prices = {
    primary: getPrimaryPriceString(product, shouldIgnoreQuantity), 
    secondary: getSecondaryPriceString(product, shouldIgnoreQuantity),
  }

  return {
    prices,

    suffixText: {
      primary: finalCurrency, 
      secondary: `${finalCurrency} (cu TVA)`,
    },
    finalCurrency,
    hasPrice: {
      primary: !isNaN(prices.primary),
      secondary: !isNaN(prices.secondary),
    }
  }

}


const getPrimaryPriceString = (product, shouldIgnoreQuantity = false) => {
  try { return getPrimaryPriceNumberOrThrow(product, shouldIgnoreQuantity) } 
  catch (error) { return '-' }
}

const getSecondaryPriceString = (product, shouldIgnoreQuantity = false) => {
  try { return getSecondaryPriceNumberOrThrow(product, shouldIgnoreQuantity) }
  catch (error) { return '-' }
}

const getPrimaryPriceNumberOrThrow = (product, shouldIgnoreQuantity = false) => {
  const priceNetFloat = parseFloatOrThrow(product?.unitPriceNetLocal || product?.priceNet)
  
  const quantityFloat = shouldIgnoreQuantity
    ? 1
    : parseFloatOrThrow(product?.quantity)

  return roundOrThrow(priceNetFloat * quantityFloat)
  
}

const getSecondaryPriceNumberOrThrow = (product, shouldIgnoreQuantity = false) => {

  const priceGrossFloat = parseFloatOrThrow(product?.unitPriceGrossLocal || product?.priceGross)
  
  const quantityFloat = shouldIgnoreQuantity
    ? 1
    : parseFloatOrThrow(product?.quantity)

  return roundOrThrow(priceGrossFloat * quantityFloat)
  
}