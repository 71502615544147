
import { useSelector } from 'react-redux'
import { campaignDateInfo, isCampaignActive } from 'utils/dateUtils'
import { shoppingCartSelector } from 'store/selectors/dataView/dataView.productsListFinal.selector'
import blackFridayImage from 'assets/Campaign/blackFriday.webp'
import useIsLogged from 'utils/useIsLogged'


const useCampaignInfo = () => {
  
  const campaignApiInfo = useSelector(state => state?.shop?.campaignApiInfo)
  const shoppingCart = useSelector(shoppingCartSelector)
  const isShoppingCartEmpty = !Boolean(shoppingCart?.products?.[0]?.length)
  const isLogged = useIsLogged()

  const getShouldShowCampaignCheckbox = () => {
      if(!isCampaignActive()) return false
      if(!campaignConfig.doesAffectCart) return false
      if(isShoppingCartEmpty) return false
      if(campaignApiInfo?.isCompanyInCampaign) return false
      return true
  }

  const getInitialCampaignCheckboxState = () => {
      if(!isCampaignActive()) return false
      if(campaignApiInfo?.isUserInCampaign) return true
      return false
  }

  const getShouldShowFloatingButton = () => {
    if(isLogged) return false
    // The bloating button must be active DURING and AFTER the campaign.
    return !campaignDateInfo.isBeforeCampaign
  }

  return {
    /** campaignApiInfo?.isUserInCampaign is a misleading name. 
     * It actually becomes false if the user REFUSED the campaign 
     * in the past. Meant to be used to decide the initial state 
     * of the campaign-related checkbox. */ 
    initialCampaignCheckboxState: getInitialCampaignCheckboxState(),
    shouldShowCampaignCheckbox: getShouldShowCampaignCheckbox(), 
    shouldShowFloatingButton: getShouldShowFloatingButton(),
  }

}


/**
 * The campaign ended but SOME of its implemented functionalities 
 * must stay active for some more time. This function is the 
 * decision point for those.
 */
export const isCampaignPartiallyActive = () => 
  true


export const campaignConfig = {
  url: campaignDateInfo.isCampaignActive ? 'https://uniprest.ro/black-friday/' : 'https://uniprest.ro/promotii/',
  name: campaignDateInfo.isCampaignActive ? 'Black Friday' : 'Promoții',
  doesAffectRouting: false, 
  doesAffectCart: false,
  imageUrl: blackFridayImage,
  hasNarrowDashboardBanner: false,
  isBeforeCampaign: campaignDateInfo.isBeforeCampaign,
  isAfterCampaign: campaignDateInfo.isAfterCampaign,
  isCampaignActive: campaignDateInfo.isCampaignActive,
}

export default useCampaignInfo


export const GoToCampaign = () => {

  window.location.href = 'https://uniprest.ro/black-friday/'

  return null

}