import moment from "moment"

// TODO: change lang from session
//import 'moment/locale/hu';
import "moment/locale/ro"

//TODO: review> should we move these to staticData?
export const DATE_FORMAT_DEFAULT = "YYYY-MM-DD"
export const DATE_FORMAT_STANDARD = "YYYY.MM.DD."
export const DATE_FORMAT_STANDARD_WMONTH_STR = "DD MMM YYYY"
export const DATE_FORMAT_STANDARD_WDAY = "YYYY.MM.DD, dddd"
export const DATE_FORMAT_STANDARD_WTIME = "DD MMM YYYY HH:mm"
export const DATE_FORMAT_STANDARD_WDAYTIME = "ddd, D MMM  YYYY, h:mm"
export const DATE_FORMAT_DAY_ONLY = "dddd"
export const DATE_FORMAT_TIME_ONLY = "HH:mm"

export const formatDate = (value, format) => {
  return value
    ? (moment.isMoment(value) ? value : moment(value)).format(
        format ?? DATE_FORMAT_DEFAULT
      )
    : null
}

export const getToday = () => moment()
export const getDayOfWeekFromDate = (value) =>
  formatDate(value, DATE_FORMAT_DAY_ONLY)
export const getTimeFromDate = (value) =>
  formatDate(value, DATE_FORMAT_TIME_ONLY)
export const formatDateStandard = (value) =>
  formatDate(value, DATE_FORMAT_STANDARD)
export const formatDateStandardWDay = (value) =>
  formatDate(value, DATE_FORMAT_STANDARD_WDAY)
export const formatDateStandardWMonthStr = (value) =>
  formatDate(value, DATE_FORMAT_STANDARD_WMONTH_STR)
export const formatDateWTime = (value) =>
  formatDate(value, DATE_FORMAT_STANDARD_WTIME)
export const formatDateWDayTime = (value) =>
  formatDate(value, DATE_FORMAT_STANDARD_WDAYTIME)

export const formatISOMoment = (date) =>
  moment.isMoment(date) ? date.format() : moment(date).format()

export const calcDateDifference = (date1, date2) => {
  return moment.duration(moment(date2).diff(moment(date1)))
}

// dateType: ex. 'd' or 'day', 'H' or 'hours'...
export const addAdditionalTimeToDate = (date, addValue, dateType) => {
  return (moment.isMoment(date) ? date : moment(date)).add(addValue, dateType)
}

export const addHoursAndMinutesToDate = (date, hours, minutes) => {
  return addAdditionalTimeToDate(
    addAdditionalTimeToDate(date, hours, "H"),
    minutes,
    "m"
  )
}

export const getMonthsDayIntervallum = (
  date,
  tillToday,
  manualEndDay = null
) => {
  date = date ? (moment.isMoment(date) ? date : moment(date)) : moment()
  let lastDay =
    manualEndDay === null
      ? tillToday
        ? moment().format("DD")
        : date.endOf("month").format("DD")
      : manualEndDay
  return moment(date.startOf("month")).format("DD") + " - " + lastDay + "."
}

export const dateIsAfter = (date) => {
  return date ? moment(date).isAfter() : date
}


export const getValidDateOrNull = dateInput => {
  if (!dateInput) return null
  
  try {
    const newDate = new Date(dateInput)
    const newDateFromInt = new Date(parseInt(dateInput))

    const isNewDateValid = !isNaN(newDate?.getTime())
    const isNewDateFromIntValid = !isNaN(newDateFromInt?.getTime())

    if(isNewDateValid) return newDate
    if(isNewDateFromIntValid) return newDateFromInt

    return null
    
  } catch (error) {
    console.error(error)
    return null
  }

}

const getCampaignDateInfo = () => {
  
  const start = getValidDateOrNull(process?.env?.REACT_APP_CAMPAIGN_START_DATE)  
  const end = getValidDateOrNull(process?.env?.REACT_APP_CAMPAIGN_END_DATE)  
  
  const currentDate = new Date()

  if(!start || !end) return { 
    isBeforeCampaign: false, isAfterCampaign: true, isCampaignActive: false
  }

  const isBeforeCampaign = currentDate < start
  const isAfterCampaign = currentDate > end
  const isCampaignActive = currentDate >= start && currentDate <= end

  return { isBeforeCampaign, isAfterCampaign, isCampaignActive }

}


export const campaignDateInfo = getCampaignDateInfo()
export const isCampaignActive = () => campaignDateInfo.isCampaignActive