import React from "react"
import { Link } from "react-router-dom"

/**
 * Uses the Link component from react-router-dom.
 * Removes the annoying default style of the <a data-test-id='JPH4'> tag.
 * If no 'to' prop is provided, it will log an error
 * and return a Link to the 404 page.
 */
const MyLink = ({ children, to, shouldNotRenderAsLink, ...rest }) => {
  if (!to && !shouldNotRenderAsLink) console.error(`MyLink: 'to' prop is required`)

  const combinedStyle = {
    ...styles.link,
    ...rest.style,
  }

  const combinedRest = {
    ...rest,
    style: combinedStyle,
  }

  if(shouldNotRenderAsLink) return (
    <span data-test-id='L1Wt' {...combinedRest}>{children}</span>
  )

  if(to?.startsWith?.('http')) return (
    <a href={to || "/404"} {...combinedRest}>{children}</a>
  )

  return (
    <Link to={to || "/404"} {...combinedRest}>
      {children}
    </Link>
  )
}

const styles = {
  link: {
    color: "inherit",
    textDecoration: "none",
  }
}

export default MyLink
