import { Lang, lang } from "components/Lang/Lang"
import { legalDocuments } from "views/guest/terms"

const footerContent = {
  //copy: ,//'© Uniprest Instal SRL, ',
  FooterLinks: [
    {
      url: legalDocuments?.terms?.url,
      label: <Lang value="footer.termsConditions" />
    },
    {
      url: legalDocuments?.cookies?.url,
      label: <Lang value="footer.cookies" />
    }, //'Politica de stocare şi utilizare cookies' - Politica de utilizare Cooki-uri
    {
      url: legalDocuments?.contract?.url,
      label: <Lang value="footer.contractConditions" />
    }, //'Condiţii contractuale generale' new one
    {
      url: legalDocuments?.marketing?.url,
      label: <>
        Nota de informare privind prelucrarea datelor 
        <br />
        cu caracter personal în scopuri de marketing
      </>,
    },
    {
      url: legalDocuments?.candidates?.url,
      label: <Lang value={legalDocuments?.candidates?.title}/>
    },
    { 
      url: "/shop/support", 
      label: "Contact",
    }, 

  ],
  social: <Lang value="footer.social" /> //Urmareste-ne pe retelele sociale:
}
export default footerContent
