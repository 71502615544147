import { withLocalize } from "react-localize-redux"
import "./index.scss"


export const legalDocuments = {
  terms: {
    title: 'footer.termsAndConditionsOfUse', 
    url: 'https://www.uniprest.ro/media/termeni-si-conditii-de-utilizare-a-uniprest-ro.pdf',
  }, 
  cookies: {
    title: 'footer.cookieStorageAndUsagePolicy',
    url: 'https://www.uniprest.ro/media/politica-de-stocare-si-utilizare-cookies.pdf',
  }, 
  contract: {
    title: 'footer.generalContractualConditions',
    url: 'https://www.uniprest.ro/media/conditii-contractuale-generale.pdf',
  },
  marketing: {
    title: 'footer.infoNoticeRegardingTheProcessing',
    url: 'https://www.uniprest.ro/media/nota-de-informare-privind-prelucrarea-datelor-cu-caracter-personal-in-scopuri-de-marketing.pdf',
  }, 
  candidates: {
    title: 'footer.candidateInformationNotice',
    url: 'https://www.uniprest.ro/media/nota-de-informare-candidati.pdf',
  },
}

const DocumentPage = () => {

  const pageHeader = (
    <div data-test-id='flAd' className="topTitleContainer row">
      {Object.keys(legalDocuments || {})?.map?.(documentId => (
        <LegalLinkLocalized key={documentId} documentId={documentId} />
      ))}
    </div>
  )

  return (
    <div data-test-id='8BI1' className="termeniContainer ">
      {pageHeader}
    </div>
  )
}
export default DocumentPage 

const LegalLink = ({ documentId, translate }) => {

  const legalDocument = legalDocuments?.[documentId]

  if (!legalDocument) return null

  return <div data-test-id='u47c' className="itemContainer">
    <a 
      data-test-id='WvKH'
      className="btn"
      href={legalDocument?.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div data-test-id='3e5n' className="textTitleContainer">
        <div data-test-id='6CA6' className="title">{translate?.(legalDocument?.title)} </div>
      </div>
    </a>
  </div>
}

const LegalLinkLocalized = withLocalize(LegalLink)
